<template>
  <div>
    <div id="login">
      <v-container>
        <div>
          <v-card flat style="background-color: transparent; padding: 0px">
            <v-card-text class="pa-0">
              <v-form ref="loginForm" v-model="validLogin" lazy-validation v-on:submit.prevent style="max-width: 400px">
                <v-container fluid class="pa-0" :style="{'margin-top': $vuetify.breakpoint.mdAndUp ? '75px' : '0px'}">
                  <v-row>
                    <v-col>
                      <span class="text-h4 logo--text font-weight-bold">Log</span>
                      <span class="text-h4 logo--text font-weight-light">In</span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5 mb-5">
                    <v-col cols="5" class="mt-3">
                      <v-divider/>
                    </v-col>
                    <v-col cols="2">
                      <span class="">or</span>
                    </v-col>
                    <v-col cols="5" class="mt-3">
                      <v-divider/>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col>
                      <v-text-field
                          outlined
                          dense
                          color="#00BC74"
                          hide-details="auto"
                          type="email"
                          prepend-inner-icon="mdi-email"
                          v-model="loginData.email"
                          :rules="emailRules"
                          label="Email"
                          key="loginEmail"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <v-text-field
                          outlined
                          dense
                          color="#00BC74"
                          hide-details="auto"
                          prepend-inner-icon="mdi-key"
                          v-model="loginData.password"
                          :rules="[rules.required, rules.min]"
                          label="Password"
                          type="password"
                          key="loginPassword"
                      />
                      <div class="extras" style="float: right;">
                        <a href="#" class="logo--text">Forgot Password?</a>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <v-btn
                          color="#00BC74"
                          class="white--text"
                          block
                          :disabled="!validLogin || loading"
                          @click.prevent.stop="loginHandler"
                      >
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import Validation from "../mixins/Validation";
import User from "../../services/User";

export default {
  name: "GPLogin",
  mixins: [Validation],
  data() {
    return {
      validLogin: false,
      showForgotPassword: false,
      signUp: {
        firstName: '',
        lastName: '',
        orgName: '',
        email: '',
        password: ''
      },
      loginData: {
        email: '',
        password: '',
        device_name: 'Browser'
      },
      loading: false,
    }
  },
  methods: {
    loadingHandler(status) {
      this.loading = status;
      this.validLogin = status;
    },
    loginHandler() {
      const me = this;
      if (!this.$refs.loginForm.validate()) {
        return;
      }

      User.login(me.loginData)
          .then((response) => {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("token", response.data);
            this.$router.push({name: 'Dashboard'});
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.$store.commit('SET_SNACKBAR', {
                type: 'error',
                text: error.response.data.errors["email"][0]
              });
            }
          })
    },
  }
}
</script>


<style lang="scss" scoped>

#login {
  margin-top: 0px;
}

.extras {
  text-align: center;

  a {
    text-decoration: none;
  }
}

.error-message {
  color: red;
}
</style>
